import { FunctionComponent } from "react";
import styles from "./Success.module.css";

const Success: FunctionComponent = () => {
  return (
    <div className={styles.success}>
      <div className={styles.frameParent}>
        <div className={styles.jWrapper}>
          <img className={styles.jIcon} alt="" src="/j1.svg" />
        </div>
        <div className={styles.thankYouFor}>Thank You for Your Purchase!</div>
        <div className={styles.checkYouInbox}>
          check you inbox for more info!
        </div>
      </div>
    </div>
  );
};

export default Success;
