import { FunctionComponent } from "react";
import styles from "./ChatContainer.module.css";

const ChatContainer: FunctionComponent = () => {
  return (
    <div className={styles.frameParent}>
      <div className={styles.connectedAccountWrapper}>
        <div className={styles.connectedAccount}>CONNECTED ACCOUNT</div>
      </div>
      <div className={styles.telegramParent}>
        <div className={styles.telegram}>TELEGRAM</div>
        <div className={styles.telegramUsernameWrapper}>
          <div className={styles.telegramUsername}>@fullstackdeyb</div>
        </div>
      </div>
      <div className={styles.telegramParent}>
        <div className={styles.telegram}>DISCORD</div>
        <div className={styles.telegramUsernameWrapper}>
          <div className={styles.telegramUsername}>jaseunda#8568</div>
        </div>
      </div>
      <div className={styles.telegramParent}>
        <div className={styles.telegram}>FACEBOOK MESSENGER</div>
        <div className={styles.proOnlyWrapper}>
          <div className={styles.telegramUsername}>pro only</div>
        </div>
      </div>
      <div className={styles.telegramParent}>
        <div className={styles.telegram}>WHATSAPP</div>
        <div className={styles.proOnlyWrapper}>
          <div className={styles.telegramUsername}>pro only</div>
        </div>
      </div>
    </div>
  );
};

export default ChatContainer;
