import { FunctionComponent } from "react";
import styles from "./BasicPackContainer.module.css";

type BasicPackContainerType = {
  packageName?: string;
  packagePrice?: string;
  packageChatCount?: string;
};

const BasicPackContainer: FunctionComponent<BasicPackContainerType> = ({
  packageName,
  packagePrice,
  packageChatCount,
}) => {
  return (
    <div className={styles.basicInner}>
      <div className={styles.jParent}>
        <img className={styles.jIcon} alt="" src="/j3.svg" />
        <div className={styles.frameWrapper}>
          <div className={styles.frameParent}>
            <div className={styles.frameGroup}>
              <div className={styles.basicPackWrapper}>
                <div className={styles.basicPack}>{packageName}</div>
              </div>
              <div className={styles.chatsParent}>
                <div className={styles.basicPack}>{packagePrice}</div>
                <div className={styles.php5436}>{packageChatCount}</div>
              </div>
            </div>
            <div className={styles.featureParent}>
              <div className={styles.basicPack}>FEATURE</div>
              <div className={styles.limitedIntelligentQaContainer}>
                <p
                  className={styles.limitedIntelligentQa}
                >{`- Limited Intelligent Q&A `}</p>
                <p
                  className={styles.limitedIntelligentQa}
                >{`- Image Generation `}</p>
                <p
                  className={styles.limitedIntelligentQa}
                >{`- File Creation and Customization  `}</p>
              </div>
            </div>
            <div className={styles.featureParent}>
              <div className={styles.basicPack}>POWER</div>
              <div className={styles.model10SimilarContainer}>
                <p className={styles.limitedIntelligentQa}>
                  - Model 1.0 (Similar to ChatGPT3.5)
                </p>
                <p className={styles.limitedIntelligentQa}>- 800 words</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buy}>
          <img className={styles.logoIcon} alt="" src="/logo1.svg" />
        </div>
      </div>
    </div>
  );
};

export default BasicPackContainer;
