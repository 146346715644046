import { FunctionComponent } from "react";
import styles from "./SolutionContainer.module.css";

const SolutionContainer: FunctionComponent = () => {
  return (
    <div className={styles.jParent}>
      <img className={styles.jIcon} alt="" src="/j1.svg" />
      <div className={styles.frameParent}>
        <div className={styles.aCuttingEdgeSolutionForAlParent}>
          <div className={styles.aCuttingEdgeSolution}>
            A Cutting-Edge Solution for All Your Needs
          </div>
          <div className={styles.scodeAiThe}>
            SCode AI, the groundbreaking AI-powered platform designed to
            streamline your workflow, enhance your creativity, and boost your
            productivity.
          </div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.loginWrapper}>
            <div className={styles.login}>login</div>
          </div>
          <div className={styles.community}>community</div>
        </div>
      </div>
    </div>
  );
};

export default SolutionContainer;
