import { FunctionComponent } from "react";
import BasicPackContainer from "../components/BasicPackContainer";
import styles from "./Lite.module.css";

const Lite: FunctionComponent = () => {
  return (
    <div className={styles.lite}>
      <div className={styles.basicButtonParent}>
        <div className={styles.basicButton}>Basic</div>
        <div className={styles.studentButton}>Student</div>
        <div className={styles.proButton}>Pro</div>
      </div>
      <BasicPackContainer
        packageName="Lite Pack"
        packagePrice="50CHATS"
        packageChatCount="PHP 27.18"
      />
    </div>
  );
};

export default Lite;
