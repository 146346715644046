import { FunctionComponent } from "react";
import styles from "./Login.module.css";

const Login: FunctionComponent = () => {
  return (
    <div className={styles.login}>
      <div className={styles.jParent}>
        <img className={styles.jIcon} alt="" src="/j.svg" />
        <div className={styles.welcomeToScodeAiParent}>
          <div className={styles.welcomeToScode}>Welcome to SCode AI</div>
          <div className={styles.imGladToContainer}>
            <p className={styles.imGladTo}>I’m glad to see you here, choose</p>
            <p className={styles.imGladTo}>way to login</p>
          </div>
        </div>
      </div>
      <div className={styles.telegramParent}>
        <div className={styles.telegram}>
          <img className={styles.telegramIcon} alt="" src="/-telegram1.svg" />
        </div>
        <div className={styles.telegram}>
          <img
            className={styles.telegramIcon}
            alt=""
            src="/-facebook-messengeroriginalfalse1.svg"
          />
        </div>
        <div className={styles.telegram}>
          <img className={styles.telegramIcon} alt="" src="/-discord1.svg" />
        </div>
        <div className={styles.telegram}>
          <img className={styles.telegramIcon} alt="" src="/-whatsapp1.svg" />
        </div>
      </div>
      <div className={styles.joinToApiWaitlistWrapper}>
        <div className={styles.joinToApi}>JOIN TO API WAITLIST</div>
      </div>
    </div>
  );
};

export default Login;
