import { FunctionComponent } from "react";
import ProContainer from "../components/ProContainer";
import styles from "./Pro.module.css";

const Pro: FunctionComponent = () => {
  return (
    <div className={styles.pro}>
      <div className={styles.basicParent}>
        <div className={styles.basic}>Basic</div>
        <div className={styles.student}>Student</div>
        <div className={styles.pro1}>Pro</div>
      </div>
      <div className={styles.proInner}>
        <ProContainer />
      </div>
    </div>
  );
};

export default Pro;
