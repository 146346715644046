import { FunctionComponent } from "react";
import styles from "./Beta.module.css";

const Beta: FunctionComponent = () => {
  return (
    <div className={styles.beta}>
      <div className={styles.groupParent}>
        <img className={styles.frameChild} alt="" src="/group-5.svg" />
        <div className={styles.welcomeToScodeAiParent}>
          <div className={styles.welcomeToScodeContainer}>
            <span>Welcome to SCode</span>
            <span className={styles.ai}> AI</span>
          </div>
          <div className={styles.imGladToContainer}>
            <p className={styles.imGladTo}>I’m glad to see you here, choose</p>
            <p className={styles.imGladTo}>way to login</p>
          </div>
        </div>
      </div>
      <div className={styles.telegramParent}>
        <div className={styles.telegram}>
          <img className={styles.telegramIcon} alt="" src="/-telegram.svg" />
        </div>
        <div className={styles.telegram}>
          <img
            className={styles.telegramIcon}
            alt=""
            src="/-facebook-messengeroriginalfalse.svg"
          />
        </div>
        <div className={styles.telegram}>
          <img className={styles.telegramIcon} alt="" src="/-discord.svg" />
        </div>
        <div className={styles.telegram}>
          <img className={styles.telegramIcon} alt="" src="/-whatsapp.svg" />
        </div>
      </div>
      <div className={styles.joinToBetaProgramWrapper}>
        <div className={styles.joinToBeta}>JOIN TO BETA PROGRAM</div>
      </div>
    </div>
  );
};

export default Beta;
