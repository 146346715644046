import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Student from "./pages/Student";
import Success from "./pages/Success";
import Pro from "./pages/Pro";
import Account from "./pages/Account";
import Lite from "./pages/Lite";
import Basic from "./pages/Basic";
import Beta from "./pages/Beta";
import Login from "./pages/Login";
import { useEffect } from "react";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/student":
        title = "";
        metaDescription = "";
        break;
      case "/success":
        title = "";
        metaDescription = "";
        break;
      case "/pro":
        title = "";
        metaDescription = "";
        break;
      case "/account":
        title = "";
        metaDescription = "";
        break;
      case "/lite":
        title = "";
        metaDescription = "";
        break;
      case "/basic":
        title = "";
        metaDescription = "";
        break;
      case "/beta":
        title = "";
        metaDescription = "";
        break;
      case "/login":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/student" element={<Student />} />
      <Route path="/success" element={<Success />} />
      <Route path="/pro" element={<Pro />} />
      <Route path="/account" element={<Account />} />
      <Route path="/lite" element={<Lite />} />
      <Route path="/basic" element={<Basic />} />
      <Route path="/beta" element={<Beta />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}
export default App;
