import { FunctionComponent } from "react";
import styles from "./ProContainer.module.css";

const ProContainer: FunctionComponent = () => {
  return (
    <div className={styles.jParent}>
      <img className={styles.jIcon} alt="" src="/j.svg" />
      <div className={styles.frameWrapper}>
        <div className={styles.frameParent}>
          <div className={styles.frameGroup}>
            <div className={styles.proWrapper}>
              <div className={styles.pro}>{`Pro `}</div>
            </div>
            <div className={styles.unlimitedChatsParent}>
              <div className={styles.pro}>UNLIMITED CHATS</div>
              <div className={styles.beta}>BETA</div>
            </div>
          </div>
          <div className={styles.featureParent}>
            <div className={styles.pro}>FEATURE</div>
            <div className={styles.allBasicPackContainer}>
              <p
                className={styles.allBasicPack}
              >{`- All Basic Pack Features `}</p>
              <p className={styles.allBasicPack}>
                - Extended capabilities in Code
              </p>
            </div>
          </div>
          <div className={styles.powerParent}>
            <div className={styles.pro}>POWER</div>
            <div className={styles.model24BetterContainer}>
              <p className={styles.allBasicPack}>
                - Model 2.4 (Better than ChatGPT)
              </p>
              <p className={styles.allBasicPack}>- 3,200,000 words</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.googleForm}>
        <div className={styles.pro}>INVITED ONLY</div>
      </div>
    </div>
  );
};

export default ProContainer;
