import { FunctionComponent } from "react";
import StudentPackContainer from "../components/StudentPackContainer";
import styles from "./Student.module.css";

const Student: FunctionComponent = () => {
  return (
    <div className={styles.student}>
      <div className={styles.basicButtonParent}>
        <div className={styles.basicButton}>Basic</div>
        <div className={styles.studentButton}>Student</div>
        <div className={styles.proButton}>Pro</div>
      </div>
      <div className={styles.studentInner}>
        <StudentPackContainer />
      </div>
    </div>
  );
};

export default Student;
