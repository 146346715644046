import { FunctionComponent } from "react";
import BasicPackContainer from "../components/BasicPackContainer";
import styles from "./Basic.module.css";

const Basic: FunctionComponent = () => {
  return (
    <div className={styles.basic}>
      <div className={styles.basicButtonParent}>
        <div className={styles.basicButton}>Basic</div>
        <div className={styles.studentButton}>Student</div>
        <div className={styles.proButton}>Pro</div>
      </div>
      <BasicPackContainer
        packageName="Basic Pack"
        packagePrice="100CHATS"
        packageChatCount="PHP 54.36"
      />
    </div>
  );
};

export default Basic;
