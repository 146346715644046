import { FunctionComponent } from "react";
import ChatContainer from "../components/ChatContainer";
import styles from "./Account.module.css";

const Account: FunctionComponent = () => {
  return (
    <div className={styles.account}>
      <div className={styles.accountChild} />
      <div className={styles.frameParent}>
        <div className={styles.jParent}>
          <img className={styles.jIcon} alt="" src="/j.svg" />
          <div className={styles.scodeAi}>SCode AI</div>
          <div className={styles.frameWrapper}>
            <div className={styles.telegramParent}>
              <img className={styles.telegramIcon} alt="" src="/telegram.svg" />
              <img className={styles.telegramIcon} alt="" src="/discord.svg" />
            </div>
          </div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.frameContainer}>
            <div className={styles.chatValueParent}>
              <div className={styles.chatValue}>300</div>
              <div className={styles.chatInfo}>CHATS LEFT</div>
            </div>
          </div>
          <div className={styles.shopButton}>shop</div>
        </div>
        <ChatContainer />
      </div>
      <div className={styles.accountItem} />
      <div className={styles.logout}>logout</div>
    </div>
  );
};

export default Account;
